import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CV() {
  return (
    <Container style={{ padding: '2vmin' }}>
      <Row style={{ textAlign: 'center' }}>
        <Col>
          <h4>Experience</h4>
        </Col>
        <Col>
          <h4>Skills</h4>
        </Col>
      </Row>
      <Row className='g-4'>
        <Col>
          <Card>
            <Card.Header className='d-flex gap-1'>
              Village Software
              <span>
                <Badge bg='success'>Current</Badge>
              </span>
              <span className='ms-auto'>
                <img src='./village-whiteout.svg' height={20} alt='' />
              </span>
            </Card.Header>
            <Card.Body>
              I currently work as a Junior Software Developer at Village
              Software, in Liverpool
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header className='d-flex gap-1'>
              Search Laboratory
              <span className='ms-auto'>
                <img src='./searchlab-icon.svg' height={20} alt='' />
              </span>
            </Card.Header>
            <Card.Body>
              I worked on a short term project for Search Laboratory,
              implementing an system to check if content was AI generated and
              provide a report on it.
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Technologies</Card.Header>
            <Card.Body>
              <Badge bg='info'>
                <i className='fa-brands fa-html5' /> Html
              </Badge>{' '}
              <Badge bg='info'>
                <i className='fa-brands fa-css3-alt' /> CSS
              </Badge>{' '}
              <Badge bg='info'>
                <i className='fa-brands fa-js' /> JavaScript
              </Badge>{' '}
              <Badge bg='info'>
                <i className='fa-brands fa-bootstrap' /> Bootstrap
              </Badge>{' '}
              <Badge bg='info'>
                <i className='fa-brands fa-react' /> React
              </Badge>{' '}
              {/* <Badge bg='info'><i className="fa-brands fa-angular" /> Angular</Badge>{' '} */}
              <Badge bg='info'>
                <i className='fa-brands fa-microsoft' /> .NET Core
              </Badge>{' '}
              <Badge bg='info'>
                <i className='fa-brands fa-microsoft' /> .NET Framework
              </Badge>{' '}
              {/* <Badge bg='info'><i className="fa-brands fa-java" /> Java</Badge>{' '} */}
              <Badge bg='info'>
                <i className='fa-brands fa-python' /> Python
              </Badge>{' '}
              <Badge bg='info'>SQL</Badge>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CV;

import './Layout.css';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Outlet, Link } from 'react-router-dom';


function Layout() {
  return (
    <>
    <header>
      <Navbar sticky='top' className='bg-body-tertiary' expand='sm'>
        <Container>
          <Navbar.Brand as={Link} to='/'>
            Lilith Capps | Software Developer
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to='/'>CV</Nav.Link>
              <Nav.Link as={Link} to='projects'>Projects</Nav.Link>
              <Nav.Link as={Link} to='blog'>Blog</Nav.Link>
              <div className='d-flex justify-content-evenly'>      
                <Nav.Link href="https://github.com/lilithcapps">
                  <img src="github-mark-white.svg" height={20} alt='Github' />
                </Nav.Link>
                <Nav.Link href="https://linkedin.com/in/lilithcapps">
                  <img src="linkedin-white.png" height={20} alt='LinkedIn' />
                </Nav.Link>
                <Nav.Link href="https://villagesoftware.co.uk">
                  <img src="village-whiteout.svg" height={20} alt='Village Software' />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    <Outlet />
    </>
  );
}

export default Layout;
